// Base Colors
$base-color: #F2F2F2;
$base-color-dark: #E5ECF2;
$base-color-very-dark: #D9D9D9;
$base-color-light: #FFFFFF;

// Accent Colors
$accent-color-orange: #FF9300;
$accent-color-orange-dark: darken($accent-color-orange, 10%);
$accent-color-orange-light: lighten($accent-color-orange, 10%);
$accent-color-blue: #0072C6;
$accent-color-blue-dark: darken($accent-color-blue, 10%);
$accent-color-blue-light: lighten($accent-color-blue, 10%);

// Text Colors
$text-color: #333333;
$text-color-dark: #A9A9A9;
$text-color-light: #FFFFFF;

// Background Colors
$background-color: #E2E2E2;
$background-color-dark: #333333;

// Border Colors
$border-color-base: #D9D9D9;
$border-color-dark: #333333;
$border-color-light: #D9D9D9;

// Fonts
$font-family-base: 'Open Sans', sans-serif;

// Font Sizes
$font-size-base: 1rem;
$font-size-lg: 1.25rem;
$font-size-sm: 0.875rem;


// Line Heights
$line-height-base: 1.5;
$line-height-lg: 1.5;
$line-height-sm: 1.5;


// Border Widths
$border-width-base: 1px;
$border-width-lg: 2px;
$border-width-sm: 1px;


// Border Radii
$border-radius-base: 0.25rem;
$border-radius-lg: 0.25rem;
$border-radius-sm: 0.25rem;

// Border Colors
$border-color-base: #EBEBEB;
$border-color-dark: #202124;
$border-color-light: #EBEBEB;