// import variables
@import 'utility/variables.scss';

.App {
  margin: 0;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;

  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 2em;
  
  text-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}

.App-Container {  
  margin: 1%;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2em 2em;
  grid-auto-flow: row;
  grid-template-areas:
    "To-Do-List To-Do-List Session-Timer"
    "Flashcards-Area Flashcards-Area Flashcards-Area"
    "To-Disclose To-Disclose To-Disclose";
}

.To-Do-List, .Session-Timer, .Flashcards-Area, .To-Disclose {
  border-radius: 5px;
}

.To-Do-List {  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "Task-Input-Container Task-Input-Container Task-Input-Container"
    "List-of-Tasks List-of-Tasks List-of-Tasks"
    "Action-buttons Action-buttons Action-buttons";
  grid-area: To-Do-List;
  background-color: $base-color-dark;
  max-width: 480px;
}

.Task-Input-Container { 
  grid-area: Task-Input-Container;
}

.List-of-Tasks { 
  grid-area: List-of-Tasks; 
}

.Action-buttons { grid-area: Action-buttons; }

.Session-Timer { 
  grid-area: Session-Timer; 
  background-color: $base-color-dark;
}

.Flashcards-Area { 
  grid-area: Flashcards-Area; 
  background-color: $base-color-dark;
}

.To-Disclose { 
  grid-area: To-Disclose; 
  background-color: $base-color-dark;
}


@media screen and (max-width: 768px) {
  .App-Container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "To-Do-List"
      "Session-Timer"
      "Flashcards-Area"
      "To-Disclose";
  }
}

@media screen and (min-width: 1024px) {
  .App-Container {
    align-self: center;
    min-width: 992px;
    max-width: 1100px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "To-Do-List Session-Timer"
      "Flashcards-Area Flashcards-Area"
      "To-Disclose To-Disclose";
  }
}