@import '../../../utility/variables.scss';

.To-Do-List {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    box-shadow: 0px 0px 15px #16161d56;
}

.Task-Input-Container {
    display: flex;
    margin: 1px .5em;
    
    h1 {
        text-align: left;
    }

    .Task-Input {
        height: 2em;
        border: none;
        border-radius: 5px;
        padding: 0 1em;
        margin: 0.5em 0;
        font-size: medium;
        outline: none;
        flex-grow: 1;

        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;

        background-color: $base-color;
        color: $text-color;
        box-shadow: 5px 5px 5px 0 rgba(22,22,29,.1);
    }

    .Task-Input-Counter {
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 0 1em;
        margin: 0.5em 0;
        // font-size: medium;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        padding: 0.4em;

        background-color: $base-color;
        color: $accent-color-blue;
        box-shadow: 5px 5px 5px 0 rgba(22,22,29,.1);
        // box-shadow: 15px 15px 30px 0px rgb(22 22 29 / 20%);
    }
}

.Tasks-List {
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: left;
    list-style: none;
    padding-left: .5em;
    flex-grow: 1;
    overflow-y: scroll;

    .Task-Row {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .Task-Edit-Input {
            height: 2em;
            border: none;
            border-radius: 5px;
            padding: 0 1em;
            font-size: medium;
            outline: none;
            margin: auto 0;
            flex-grow: 1;
            text-align: left;

            background-color: $base-color;
            color: $text-color;
            box-shadow: 5px 5px 5px 0 rgba(22,22,29,.1);
        }

        p {
            margin: auto 0;
            flex-grow: 1;
            color: $accent-color-blue;
        }
    }

    .Task, .Task-Completed {
        margin: 0;
        margin-bottom: 0.5em;
        width: fit-content;
        max-width: 100%;
        inline-size: 75%;
        overflow-wrap: break-word;
    }

    .Task-Completed {
        text-decoration: line-through;
    }

    .Non-Selectable {
        -webkit-user-select: none; /* Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
    }

    .Pointer {
        cursor: pointer;
    }

    .Icon-Button, .Icon-Button-Undone {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.2s ease-in-out;
        background-color: $base-color;
        color: $accent-color-orange-dark;
        margin: 5px;
        margin-left: 0;
        padding: 2px;

        box-shadow: 5px 5px 5px 0 rgba(22,22,29,.1);

        &:hover {
            background-color: $base-color-light;
            color: $accent-color-blue;
        }
    }

    .Icon-Button-Undone {
        color: $base-color-very-dark;

        &:hover {
            color: $accent-color-orange-dark;
        }
    }
}

/* width */
.Tasks-List::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.Tasks-List::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.0);
}

/* Handle */
.Tasks-List::-webkit-scrollbar-thumb {
    transition: all 0.4s ease-in-out;
    border-radius: 1px;
    background: $base-color; 
}

/* Handle on hover */
.Tasks-List::-webkit-scrollbar-thumb:hover {
    background: $base-color-light; 
}

.Action-Buttons {
    display: flex;
    justify-content: space-between;
    margin: .5em;
    justify-self: flex-end;
    
    .Clear-Completed {
        border: none;
        border-radius: 5px;
        padding: 0.5em 1em;
        margin: 0.5em 0;
        font-size: medium;
        background-color: $base-color;
        color: $text-color;
        cursor: pointer;
        transition: all 0.2s ease-in-out;


        box-shadow: 5px 5px 5px 0 rgba(22,22,29,.1);
        &:hover {
            background-color: $base-color-light;
        }
    }
}
